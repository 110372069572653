import { useEffect, useMemo } from 'react'
import { io } from 'socket.io-client'

const isDev = process.env.NODE_ENV === 'development'

export const useSocketIO = () => {
  const socket = useMemo(() => {
    const socket = io(isDev ? 'http://192.168.3.129:9001' : 'http://api.zhangkong365.com', {
      path: isDev ? '/socket.io' : '/ws-i18n/socket.io',
      transports: ['websocket', 'polling', 'flashsocket'],
    })
    socket.on('connect', () => {
      console.log('Connected')
    })
    socket.on('exception', (data) => {
      console.log('exception', data)
    })
    return socket
  }, [])

  useEffect(() => {
    return () => {
      socket.close()
    }
  }, [socket])

  return socket
}
